// console.log("in javascript/controllers/videodrop_controller")
import { Controller } from "@hotwired/stimulus"
import { getMetaValue, removeElement } from '../src/utils'

export default class VideoDrop extends Controller {
    static targets = ["dropzone", "error"];

    declare readonly dropzoneTarget: HTMLDivElement;
    declare readonly errorTarget: HTMLDivElement;

    // The url for the resource. New videos will POST to this URL.
    // Deleted will DELETE to this url + "/{code}"
    url: string = ''

    // looking for v={code}
    static youTubeCodeRe = /v=([-\w]{11})/;

    connect() {
        this.url = this.data.get("url");
        this.bindEvents();        
    }

    bindEvents() {
        // console.log("in bindEvents", this, this.dropzoneTarget)    
        this.dropzoneTarget.addEventListener('dragenter', this.handleDragEnter.bind(this));                
        this.dropzoneTarget.addEventListener('dragover', this.handleDragOver.bind(this));                    
        this.dropzoneTarget.addEventListener('dragleave', this.handleDragLeave.bind(this));
        this.dropzoneTarget.addEventListener('drop', this.handleDrop.bind(this));
    }

    handleDragEnter(e: DragEvent) {
        // console.log('dragenter', this );

        e.preventDefault()
        this.over = true 
        this.error = null      

        // The DataTransfer types and kinds are available, but the actual data is not.
        // So, we need to wait for the Drop event to determine whether the dragged DataTransfer
        // has a valid YouTube code.               
    }

    /**
     * Handle dragover event. 
     * Per spec, An element MUST add an event handle for this (and drop) in order to be a drop target.
     */
    handleDragOver(e: Event) {
        // console.log('dragover', this)

        e.preventDefault();        
    }                

    handleDragLeave(e: Event) {
        // console.log('dragleave', this);

        e.preventDefault();

        this.over = false;
        this.error = null;
    }

    handleDrop(e: DragEvent) {
        // console.log('drop', this);

        e.preventDefault()
        
        let code = this.getCode(e);

        if (!code) {
            this.error = "Sorry, something was dropped, but it does not appear to be a YouTube video";
            this.over = false
            return;
        }

        this.addVideo(code);
        this.over = false;
        this.error = null;        
    }

    getCode(e: DragEvent) {
        // console.log('getCode')

        // start by looking for the code on a uri-list        
        let text = e?.dataTransfer?.getData('text/uri-list');
        if (text) {
            let match = text.match(VideoDrop.youTubeCodeRe);
            if (Array.isArray(match) && match.length > 1) {
                return match[1]
            }
        }
        // not found. check the other DataTransferItems that are of type text
        if (e.dataTransfer.items != null) {
            for (var i = 0; i < e.dataTransfer.items.length; i++) {
                let dti = e.dataTransfer.items[i]                            
                if (dti.kind === "string" && dti.type !== 'text/uri-list') {
                    // console.log(`kind: ${dti.kind} type: ${dti.type}`)
                    text = e.dataTransfer.getData(dti.type)
                    // console.log(`text: ${text}`)
                    if (text) {
                        let match = text.match(VideoDrop.youTubeCodeRe);
                        if (Array.isArray(match) && match.length > 1) {
                            return match[1]
                        }
                    }                    
                }
            }
        }

        return null
    }

    /**
     * Indicate whether the dragged item is over the drop target
     */
    set over(isOver: boolean) {
        if (isOver) {
            this.dropzoneTarget.classList.add('over'); 
        } else {
            this.dropzoneTarget.classList.remove('over')
        }
    }        

    /**
     * Sets the text of the error message. If the message is true-y,
     * adds .error to the dropzone class. If the message is false-y, 
     * remove .error from the class. 
     */
    set error(msg) {
        this.errorTarget.textContent = msg
        if (msg) {
            setTimeout(()=>{ this.error = null }, 5000)
        }    
    }

    async addVideo(code: string) {
        // send a request to the server to add the video to the property
        let data = { video: {code: `https://www.youtube.com/embed/${code}` }}
        let body = JSON.stringify(data)

        try{
            let resp = await fetch(this.url, {
                method: 'POST',
                headers: this.headers,
                body
            })
            if (! resp.ok) {
                throw new Error(`Status text is ${resp.statusText}`)
            }
            window.location = this.url
        } catch(err) {
            console.error(err.message)
            this.error = `There was a problem adding the video on the server: ${err.message}`
            return
        }
         
        // note return above
    }

    get headers() {
        return { 
            "Content-Type": "application/json",           
            "X-CSRF-Token": getMetaValue("csrf-token") 
        }
    }
}
