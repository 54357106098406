import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import { getMetaValue } from '../src/utils'

/**
 * This is used to change the sequence of items within a parent.
 * It is used along with Sortable. Sortable takes care of the drag/drop
 * and animating the motion of the dragged item and actually changing the DOM.
 * When the item is dropped, the "sequence" method is called. That method finds
 * all of the children within the controller scope with the "item" class, 
 * gets the resource ID from each using the "data-id" attribute, converts the
 * array to JSON and POSTS to the "data-url"
 * 
 * Here's an example:
 * 
 * <tbody data-controller="sequence" 
 *        data-action="drop->sequence#sequence" 
 *        data-url="<%= sequence_admin_property_quotes_path %>">
 *   <% @quotes.each do | quote | %>
 *     <tr class="item" data-id="<%= quote.id %>">
 *       <td><span class="grip mr-3"><i class="fas fa-grip-vertical"></i></span>             
 *       <td><%= quote.quote %></td>
 *       <td><%= quote.attribution %></td>     
 *     </tr>
 *   <% end %>
 * </tbody>
 * 
 * This allows each quote to be dragged up or down within the table. If the first quote had
 * data-id="1" and the second quote had data-id="2", and the first is dragged and dropped 
 * below the second, then a POST will me made to sequence_admin_property_quotes_path
 * with the value ['2', '1']. It is the job of the controller sequence action to 
 * change the order of the quotes within the database to ensure that quote 2
 * will display before quote 1.
 * 
 * An example of the properties sent to the controller:
 * 
 * Parameters: {"_json"=>["2", "1"], "property_id"=>"1", "property_quote"=>{"_json"=>["2", "1"]}}
 * 
 * An example sequence action: (assumes "before_action :set_property" was set)
 * 
 * def sequence
 *   order = params["_json"]
 *   order.each_with_index do | id, index |
 *     quote = @property.quotes.find(id)
 *     if quote
 *       quote.update(order: index + 1)
 *     end
 *   end
 * end
 * 
 * (of course this could be made much more efficient)
 */
export default class SequenceController extends Controller {
    connect() {
        var sortable = Sortable.create(this.element, {
            animation: 150,
            ghostClass: 'sortable-ghost'
        })
    }

    async sequence(event) {
        let items = this.element.getElementsByClassName('item')
        let item_ids = Array.from(items).map(item => item.dataset.id)
        let url = this.element.dataset.url
        
        let body = JSON.stringify(item_ids)

        // todo: display the error is this fails
        try{
            let resp = await fetch(url, {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json", 
                    "X-CSRF-Token": getMetaValue("csrf-token") 
                },
                body
            })
            if (!resp.ok) {
                throw new Error(resp.statusText)
            }          
        } catch(err) {
            console.log(err.message)
            return
        }
    }
}