// console.log('starting javascript/application')

import '@hotwired/turbo-rails'
import 'trix'
import '@rails/actiontext'
import './controllers'
import * as ActiveStorage from '@rails/activestorage'
import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'
import LocalTime from 'local-time'

window['Alpine'] = Alpine
Alpine.start()

ActiveStorage.start()
LocalTime.start()

// https://discuss.hotwired.dev/t/call-stimulus-function-from-google-maps-callback/191

window["dispatchMapsEvent"] = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event["args"] = args
  window.dispatchEvent(event)
}