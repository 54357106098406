import { Controller } from "@hotwired/stimulus"

/*

Put the controller directly on the form and changing an element (such as a select) will submit the form

Example:

    <%= search_form_for [:admin, @q], data: {controller: "search"} do |f| %> 
        <div  class="mt-2 flex flex-col space-y-8 items-fill md:flex-row md:space-y-0 md:space-x-8 md:items-end">        
            ...

            <div>
                <%= f.label :status_eq, "Status", {class: "mt-0"} %>
                <%= f.collection_select :status_eq, License.status_select, :last, :first, {include_blank: "Active"}, 
                    {data: {action: "search#submit"}} %>
            </div>

            <%= f.submit("Search", class: "btn-secondary") %>    
        </div>
    <% end %>

*/

export default class extends Controller {
    submit() {
        this.element.submit()
    }
}
