// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

// console.log("In javascript/controllers/index.js")

import { application } from "./application"

import DropZoneController from './dropzone_controller'
import SequenceController from './sequence_controller'
import VideoDropController from './videodrop_controller'
import SearchController from './search_controller'
import MapsController from './maps_controller'

application.register("dropzone", DropZoneController)
application.register("sequence", SequenceController)
application.register("videodrop", VideoDropController)
application.register("search", SearchController)
application.register("maps", MapsController)
