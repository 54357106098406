import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map", "marker", "checkbox"]

  declare readonly mapTarget: HTMLElement
  declare readonly markerTargets: HTMLElement[]
  declare readonly checkboxTarget: HTMLInputElement

  _map = undefined

  _markers = []

  _infoWindow = undefined

  _pin = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z"

  // when _pinsOnly is true pins are smaller, in the same color, and without the first letter of the show.
  _pinsOnly = false

  connect() {
    if (this.checkboxTarget.checked) {
      this._pinsOnly = true
    }
    if (typeof (google) != "undefined") {
      this.initializeMap()
    }
  }

  initializeMap() {
    this._infoWindow = new google.maps.InfoWindow();
    this.createMap()
    this.addMarkers()
  }

  createMap() {
    if (this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          this.centerLat(),
          this.centerLng()
        ),
        zoom: this.zoom()
      })
    }

    return this._map
  }

  addMarkers() {
    for (let i = 0; i < this.markerTargets.length; i++) {
      this.addMarker(i)
    }
  }

  addMarker(i) {
    let m = this.markerTargets[i]

    let position = {
      lat: parseFloat(m.dataset["lat"]),
      lng: parseFloat(m.dataset["lng"])
    }

    var pinColor = this._pinsOnly ? "#ff644d" : m.dataset["status"] == 'complete' ? "#007165" : "#ff644d"
    var labelColor = m.dataset["status"] == 'complete' ? "white" : "black"

    var markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
      path: this._pin,
      anchor: { x: 12, y: 17 },
      fillOpacity: 1,
      fillColor: pinColor,
      strokeWeight: 1,
      strokeColor: "white",
      scale: this._pinsOnly ? 1.0 : 1.7,
      labelOrigin: { x: 12, y: 9 }
    };

    var label = {
      text: m.dataset["label"],
      color: labelColor,
      fontSize: "12px",
    }; // https://developers.google.com/maps/documentation/javascript/reference/marker#Symbol

    const marker = new google.maps.Marker({
      position,
      map: this._map,
      label: this._pinsOnly ? null : label,
      animation: google.maps.Animation.DROP,
      icon: markerImage,
      info: m.firstElementChild // custom for infoWindow content
    })

    marker.addListener("click", () => {
      this._infoWindow.close();
      this._infoWindow.setContent(marker.info);
      this._infoWindow.open(marker.getMap(), marker);
    });

    this._markers.push(marker)
  }

  centerLat() {
    return this.data.get("center-lat")
  }

  centerLng() {
    return this.data.get("center-lng")
  }

  zoom() {
    return parseInt(this.data.get("zoom"))
  }

  toggle(event) {
    if (this.checkboxTarget.checked) {
      this._pinsOnly = true
      this.deleteMarkers()
      this.addMarkers()
    } else {
      this._pinsOnly = false
      this.deleteMarkers()
      this.addMarkers()
    }
  }

  deleteMarkers() {
    for (let i = 0; i < this._markers.length; i++) {
      this._markers[i].setMap(null)
    }
    this._markers = []
  }
}

